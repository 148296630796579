/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import MobileNav from "./MobileNav";
import { Helmet } from "react-helmet";
import { SEO } from "./seo";

import Back2Top from "./BackToTop";

//import { FloatingActionButton } from "material-ui";

//import UpIcon from "material-ui/svg-icons/navigation/arrow-upward";

import "normalize.css"; // Note this

import "../css/styles.scss";
import ContactForm from "./ContactForm";

//import "../css/styles.css"

const Layout = (props) => {
  const [active, setActive] = useState("");
  const handleClick = () => setActive("active");
  const handleClose = () => setActive(" ");

  const [PopupStatus, setPopupStatus] = useState("hidden");
  const handlePopupClick = () => setPopupStatus("active");
  const handlePopupClose = (event) => {
    if (event.target.className === "mfp-container" || event.target.className === "mfp-close") {
      setPopupStatus("hidden");
    }
    //setPopupStatus("hidden");
    //console.log(event.target.className);
  };
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <>
      <ContactForm status={PopupStatus} onPopupClose={handlePopupClose} />
      <Header
        onClickedButton={handleClick}
        siteTitle={data.site.siteMetadata.title}
        onPopupClick={handlePopupClick}
      />

      <div className="layout__wrapper">
        <div className={`mobile__menu ${active}`}>
          <MobileNav onClickClosed={handleClose} onPopupClick={handlePopupClick} />
        </div>
        <div className={`over-dark ${active}`} onClick={handleClose}></div>
        <main>{props.children}</main>
        <Footer></Footer>
        <Back2Top
          id="back-to-top"
          className="add-fix-top"
          visibilityHeight={200}
          scrollDuration={300}
        ></Back2Top>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
